
/**
* List of orders purchase with filtering
* @module orders/orders-purchase
* @author Lucie Zdeňková <lucie.zdenek@trustica.cz>
*/

import React, {useState, useCallback, useReducer, useEffect} from 'react';
import { Table, Row, Col, Form, Dropdown, DropdownButton, Card } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { filter_rule, icompare } from '../lib/utils';
import { format_amount } from '../lib/format';
import { date_formatCZ } from '../lib/date-utils';
import { Boolean } from '../comp/boolean';
import {his_fetch, TableFetchStatusOverlay} from '../comp/FetchLoader';
import { ErrorWrap } from '../comp/errorwrap';
import { DynamicLoad, LoadingDataInfo } from '../comp/dynamic-load';
import { useTranslation } from 'react-i18next';
import { currencyTitles } from '../lists/currency_titles';
import { BooleanDropdown } from '../comp/boolean';
import { SortIcons } from '../comp/sort';
import {DynamicLoadPaged} from "../comp/dynamic-load-paged";
import {useDebounce} from "use-debounce";

const initialFilter = {
	orderCode: "",
	partnerCode: "",
	note: "",
	massFilter: "",
	amount: "",
	currencyCode: "",
	closed: null,
	receivedPercent: "",
	invoicedPercent: ""
};

function filterReducer(state, action) {
	switch (action.type) {
		case 'orderCode':
			return { ...state, orderCode: action.value };
		case 'partnerCode':
			return { ...state, partnerCode: action.value };
		case 'note':
			return { ...state, note: action.value };
		case 'massFilter':
			return { ...state, massFilter: action.value };
		case 'amount':
			return { ...state, amount: action.value };
		case 'currencyCode':
			return { ...state, currencyCode: action.value };
		case 'closed':
			return { ...state, closed: action.value };
		case 'receivedPercent':
			return { ...state, receivedPercent: action.value };
		case 'invoicedPercent':
			return { ...state, invoicedPercent: action.value };
		case 'reset':
			return initialFilter;
		default:
			return state;
	}
}

function orderReducer(state, action) {
	return action;
}
/**
 * Returns list of orders purchase
 * 
 * @param {any} userlogin - info about logged user
 * @returns {component}
 */
export function OrdersPurchasePaged({ userlogin }) {

	const [orders, setOrders] = useState([]);
	const [totalCount, setTotalCount] = useState(0);
	const [filteredCount, setFilteredCount] = useState(0);
	const [rangeCount, setRangeCount] = useState(0);
	const [offset, setOffset] = useState(0);
	const [loadedStatus, setLoadedStatus] = useState(0);
	const [filter, dispatchFilter] = useReducer(filterReducer, initialFilter);
	const [order, dispatchOrder] = useReducer(orderReducer, null);
	const [debouncedFilter, {flush}] = useDebounce(filter, 500);
	const [sortValue, setSortValue] = useState(null);
	const [direction, setDirection] = useState("up");
	const [type, setType] = useState("");

	const loadData = useCallback(function (startDate, endDate, result_received, pageSize, offset, reload = false) {
		return (
			his_fetch(
				userlogin,
				[
					{
						uri: "/api/orders/purchase/range/" + startDate + "/" + endDate + `/paged?take=${pageSize}&skip=${offset}&filter=${encodeURIComponent(JSON.stringify(debouncedFilter))}${order ? `&orderBy=${order}` : ''}`,
						json: true,
						status: setLoadedStatus,
						ok: function (resource, result) {
							if (result.error) {
								result_received(false);
							} else {
								setOrders(result.items);
								setTotalCount(result.totalCount);
								setFilteredCount(result.filteredCount);
								setRangeCount(result.rangeCount);
								result_received(true);
							}
						},
						error: function (resource, reason) {
							console.log('err: ' + reason);
							result_received(false);
						}
					}
				]
			))
	}, [userlogin, debouncedFilter, order]);

	const cleanFilters = () => {
		dispatchFilter({ type: 'reset' });
		flush();
		setSortValue(null);
		setDirection("up");
	}

	const handleChangeCode = function (event) {
		dispatchFilter({ type: 'orderCode', value: event.target.value });
		setOffset(0);
	}
	const handleChangePartner = function (event) {
		dispatchFilter({ type: 'partnerCode', value: event.target.value });
		setOffset(0);
	}
	const handleChangeNote = function (event) {
		dispatchFilter({ type: 'note', value: event.target.value });
		setOffset(0);
	}
	const handleChangeFilterApp = function (event) {
		dispatchFilter({ type: 'massFilter', value: event.target.value });
		setOffset(0);
	}
	
	useEffect(() => {
		const desc = direction === "down";
		
		if (sortValue)
			dispatchOrder(`${(desc ? "-" : "")}${sortValue}`);
		else
			dispatchOrder(null);
	}, [sortValue, direction]);

	return (
		<ErrorWrap>
			<DynamicLoadPaged
				period='1y'
				loadData={loadData} 
			    data={orders}
				offset={offset} setOffset={setOffset}
				totalCount={totalCount}
				filteredCount={filteredCount}
				rangeCount={rangeCount}
				cleanFilters={cleanFilters}
			/>
			<TableFetchStatusOverlay status={loadedStatus}>
				<OPWeb show_orders={orders}
					setOffset={setOffset}
					filterCode={filter.orderCode}
					handleChangeCode={handleChangeCode}
					filterPartner={filter.partnerCode}
					handleChangePartner={handleChangePartner}
					filterNote={filter.note}
					handleChangeNote={handleChangeNote}
					filterAmount={filter.amount}
					setFilterAmount={value => dispatchFilter({ type: 'amount', value })}
					filterCurrency={filter.currencyCode}
					setFilterCurrency={value => dispatchFilter({ type: 'currencyCode', value })}
					filterClosed={filter.closed}
					setFilterClosed={value => dispatchFilter({ type: 'closed', value })}
					filterAccepted={filter.receivedPercent}
					setFilterAccepted={value => dispatchFilter({ type: 'receivedPercent', value })}
					filterInvoiced={filter.invoicedPercent}
					setFilterInvoiced={value => dispatchFilter({ type: 'invoicedPercent', value })}
					sortValue={sortValue}
					setSortValue={setSortValue}
					direction={direction}
					setDirection={setDirection}
					setType={setType}
				/>
				<OPApp
					show_orders={orders}
					massFilterApp={filter.massFilter}
					handleChangeFilterApp={handleChangeFilterApp}
					setOffset={setOffset}
					filterAmount={filter.amount}
					setFilterAmount={value => dispatchFilter({ type: 'amount', value })}
					filterCurrency={filter.currencyCode}
					setFilterCurrency={value => dispatchFilter({ type: 'currencyCode', value })}
					filterClosed={filter.closed}
					setFilterClosed={value => dispatchFilter({ type: 'closed', value })}
					filterAccepted={filter.receivedPercent}
					setFilterAccepted={value => dispatchFilter({ type: 'receivedPercent', value })}
					filterInvoiced={filter.invoicedPercent}
					setFilterInvoiced={value => dispatchFilter({ type: 'invoicedPercent', value })}
					sortValue={sortValue}
					setSortValue={setSortValue}
					direction={direction}
					setDirection={setDirection}
					setType={setType}
				/>
			</TableFetchStatusOverlay>
			<LoadingDataInfo loadedStatus={loadedStatus} data={orders} />
		</ErrorWrap>
	)
}

/**
 * Return list of orders purchase with filters for web.
 * 
 * @param {any} show_orders - list of orders to show on one page
 * @param {string} filterCode - state for code filter
 * @param {function} handleChangeCode - function for code filter
 * @param {string} filterPartner - state for partner filter
 * @param {function} handleChangePartner - function for partner filter
 * @param {string} filterNote - state for note filter
 * @param {function} handleChangeNote - function for note filter
 * @param {string} filterAmount - state for amount filter
 * @param {function} setFilterAmount - function for amount filter
 * @param {string} filterCurrency - state for currency filter
 * @param {function} setFilterCurrency - function for currency filter
 * @param {string} filterClosed - state for closed filter
 * @param {function} setFilterClosed - function for closed filter
 * @param {string} filterAccepted - state for accepted filter
 * @param {function} setFilterAccepted - function for accepted filter
 * @param {string} filterInvoiced - state for invoices filter
 * @param {function} setFilterInvoiced - function for invoiced filter
 * @param {number} sortValue - currently valid value for sorting
 * @param {function} setSortValue - changes sorting value
 * @param {string} direction - direction of sorting - up/down
 * @param {function} setDirection - function to change direction of sorting
 * @param {function} setType - function to change type of sorting - letters/num
 * @param {function} setOffset - changes start of pagination
 * @returns {component}
 */
function OPWeb({ show_orders, filterCode, handleChangeCode, filterPartner, handleChangePartner, filterNote, handleChangeNote,
	setOffset, filterAmount, setFilterAmount, filterCurrency, setFilterCurrency, filterClosed, setFilterClosed,
	filterAccepted, setFilterAccepted, filterInvoiced, setFilterInvoiced,
	sortValue, setSortValue, direction, setDirection, setType
}) {
	const { t } = useTranslation();
	//console.log(direction);
	//console.log(sortValue);
	return (
		<div className="d-none d-lg-block">
			<Table hover size="sm" striped >
				<thead className="beGray">
					<tr>
						<th className="pb-2">
							<Form.Label className="pb-1">
								<p className="mb-2">{t('doc_code')}</p>
								<SortIcons name={'orderCode'} sortValue={sortValue} setSortValue={setSortValue}
									direction={direction} setDirection={setDirection} setType={setType}
								/>
							</Form.Label>
							<Form.Group controlId="filterCode">
								<Form.Control type="text"
									placeholder={"🔍 "}
									value={filterCode}
									onChange={handleChangeCode} />
							</Form.Group>

						</th>
						<th className="pb-2">
							<Form.Group controlId="filterPartner">
								<Form.Label>{t('biz-partner_code')}</Form.Label>
								<Form.Control type="text"
									placeholder={"🔍 "}
									value={filterPartner}
									onChange={handleChangePartner} />
							</Form.Group>
						</th>
						<th width="8%" className="pb-3">
							<p className='mb-2 pb-1'>{t('date')}</p>
							<SortIcons name={'orderDate'} sortValue={sortValue} setSortValue={setSortValue}
								direction={direction} setDirection={setDirection} setType={setType} numeric
							/></th>
						<th className="pb-2">
							<Form.Group controlId="filterNote">
								<Form.Label>{t('note')}</Form.Label>
								<Form.Control type="text"
									placeholder={"🔍 "}
									value={filterNote}
									onChange={handleChangeNote} />
							</Form.Group>
						</th>
						<th className="pb-2 text-end">
							<Form.Group controlId="filterAmount">
								<Form.Label>{t('biz-amount_money')}</Form.Label>
								<Form.Control type="text"
									placeholder="&#128269; > < ="
									value={filterAmount} onChange={(ev) => { setFilterAmount(ev.target.value); setOffset(0); }}
								/>
							</Form.Group>
						</th>
						<th className="pb-2">
							<p className="mb-2">{t('biz-currency')}</p>
							<DropdownButton id="filterCurrency" title={t(currencyTitles[filterCurrency])} variant="light">
								{Object.keys(currencyTitles)
									.map((title, idx) =>
										<Dropdown.Item key={idx} onClick={() => { setFilterCurrency(title); setOffset(0); }} >{t(currencyTitles[title])}</Dropdown.Item>
									)}
							</DropdownButton>
						</th>
						<th className="pb-2">
							<p className='mb-2'>{t('biz-closed')}</p>
							<BooleanDropdown onChange={setFilterClosed} value={filterClosed} />
						</th>
						<th className="pb-2 text-end">
							<Form.Group controlId="filterAccepted">
								<Form.Label>{t('sys-accepted')}</Form.Label>
								<Form.Control type="text"
									placeholder="&#128269; > < ="
									value={filterAccepted} onChange={(ev) => { setFilterAccepted(ev.target.value); setOffset(0); }}
								/>
							</Form.Group>
						</th>
						<th className="pb-2 text-end">
							<Form.Group controlId="filterInvoiced">
								<Form.Label>{t('biz-invoiced')}&nbsp;%</Form.Label>
								<Form.Control type="text"
									placeholder="&#128269; > < ="
									value={filterInvoiced} onChange={(ev) => { setFilterInvoiced(ev.target.value); setOffset(0); }}
								/>
							</Form.Group>
						</th>
					</tr>
				</thead>
				<tbody>
					{
						show_orders.map(
							function (ord) {
								return (
									<LinkContainer to={{ pathname: "/orders/purchase/" + ord.orderCode }}
										key={ord.orderCode}>
										<tr key={ord.orderCode}>
											<td>{ord.orderCode}</td>
											<td>{ord.partnerCode}</td>
											<td>{date_formatCZ(ord.orderDate)}</td>
											<td>{ord.note}</td>
											<td className="text-end">{format_amount(ord.totalAmount)}</td>
											<td>{ord.currencyCode}</td>
											<td className="text-center"><Boolean value={ord.closed} variant="onlyTrue" /></td>
											<td className="text-end">{ord.receivedPercent}</td>
											<td className="text-end">{ord.invoicedPercent}&nbsp;%</td>
										</tr>
									</LinkContainer>
								);
							}
						)
					}
				</tbody>
			</Table>
		</div>
	)
}

/**
 * Returns list of orders purchase for mobile with merged filter
 * 
 * @param {any} show_orders - list of orders to show on one page
 * @param {string} massFilterApp - state for filter
 * @param {function} handleChangeFilterApp - function for filter
 * @param {function} setOffset - changes start of pagination
 * @param {string} filterAmount - state for amount filter
 * @param {function} setFilterAmount - function for amount filter
 * @param {string} filterCurrency - state for currency filter
 * @param {function} setFilterCurrency - function for currency filter
 * @param {string} filterClosed - state for closed filter
 * @param {function} setFilterClosed - function for closed filter
 * @param {string} filterAccepted - state for accepted filter
 * @param {function} setFilterAccepted - function for accepted filter
 * @param {string} filterInvoiced - state for invoices filter
 * @param {function} setFilterInvoiced - function for invoiced filter
 * @param {number} sortValue - currently valid value for sorting
 * @param {function} setSortValue - changes sorting value
 * @param {string} direction - direction of sorting - up/down
 * @param {function} setDirection - function to change direction of sorting
 * @param {function} setType - function to change type of sorting - letters/num
 * @returns {component}
 */

function OPApp({ show_orders, massFilterApp, handleChangeFilterApp, setOffset,
	filterAmount, setFilterAmount, filterCurrency, setFilterCurrency, filterClosed,
	setFilterClosed, filterAccepted, setFilterAccepted, filterInvoiced,
	setFilterInvoiced, sortValue, setSortValue, direction, setDirection, setType }) {
	const { t } = useTranslation();

	return (
		<div className="d-lg-none">
			<br />
			<Card className='m-0 p-2 mb-2'>
				<Card.Body className='m-0 p-0'>
					<Row className='mb-2'>
						<Col>
							<p className="mb-0 bolder">{t('doc_code')}</p>
							<SortIcons name={'orderCode'} sortValue={sortValue} setSortValue={setSortValue}
								direction={direction} setDirection={setDirection} setType={setType}
							/>

						</Col>
						<Col>
							<p className='mb-0 bolder'>{t('date')}</p>
							<SortIcons name={'orderDate'} sortValue={sortValue} setSortValue={setSortValue}
								direction={direction} setDirection={setDirection} setType={setType} numeric
							/>
						</Col>
					</Row>

					<p className="mb-1">{t('mass_filter')}</p>
					<Form.Group controlId="filterCodex" className='mb-2'>
						<Form.Control type="text" placeholder={"🔍 " + t('filter_doc_code_note_partner')} value={massFilterApp} onChange={handleChangeFilterApp} />
					</Form.Group>

					<Row>
						<Col>
							<Form.Group controlId="filterAmount">
								<Form.Label className='mb-1'>{t('biz-amount_money')}</Form.Label>
								<Form.Control type="text"
									placeholder="&#128269; > < ="
									value={filterAmount} onChange={(ev) => { setFilterAmount(ev.target.value); setOffset(0); }}
								/>
							</Form.Group>
						</Col>
						<Col xs="auto">
							<p className="mb-1">{t('biz-currency')}</p>
							<DropdownButton id="filterCurrency" title={t(currencyTitles[filterCurrency])} variant="light">
								{Object.keys(currencyTitles)
									.map((title, idx) =>
										<Dropdown.Item key={idx} onClick={() => { setFilterCurrency(title); setOffset(0); }} >{t(currencyTitles[title])}</Dropdown.Item>
									)}
							</DropdownButton>
						</Col>

					</Row>
					<Row className='mt-2'>

						<Col>
							<p className='mb-1'>{t('biz-closed')}</p>
							<BooleanDropdown onChange={setFilterClosed} value={filterClosed} />
						</Col>
						<Col>
							<Form.Group controlId="filterAccepted">
								<Form.Label className='mb-1'>{t('sys-accepted')}</Form.Label>
								<Form.Control type="text"
									placeholder="&#128269; > < ="
									value={filterAccepted} onChange={(ev) => { setFilterAccepted(ev.target.value); setOffset(0); }}
								/>
							</Form.Group>
						</Col>
						<Col>
							<Form.Group controlId="filterInvoiced">
								<Form.Label className='mb-1'>{t('biz-invoiced')}&nbsp;%</Form.Label>
								<Form.Control type="text"
									placeholder="&#128269; > < ="
									value={filterInvoiced} onChange={(ev) => { setFilterInvoiced(ev.target.value); setOffset(0); }}
								/>
							</Form.Group>
						</Col>

					</Row>
				</Card.Body>
			</Card>

			<Table striped>
				<tbody>
					{show_orders.map(function (ord) {
						return (
							<LinkContainer to={{ pathname: "/orders/purchase/" + ord.orderCode }}
								key={ord.orderCode}>
								<tr>
									<td>
										<Row className="g-0">
											<Col className="beBigger me-1">{ord.orderCode}</Col>
											<Col className="text-end align-bottom">{format_amount(ord.totalAmount)} {ord.currencyCode}</Col>
										</Row>
										<Row>
											<Col>{ord.partnerCode}</Col>
											<Col className="text-end">{t('biz-closed')}: <Boolean value={ord.closed} variant="onlyTrue" /></Col>
										</Row>
										<Row>
											<Col>{date_formatCZ(ord.orderDate)}</Col>
											<Col className="text-end">{t('sys-accepted')}: {ord.receivedPercent}</Col>
										</Row>
										<Row>
											<Col>{t('note_short')}: {ord.note}</Col>
											<Col xs="auto" className="text-end">{t('biz-invoiced')}: {ord.invoicedPercent}&nbsp;%</Col>
										</Row>
									</td>
								</tr>
							</LinkContainer>
						);
					})
					}
				</tbody>
			</Table>
		</div>
	);
}