/**
* Order purchase detail mother component with fetch and checkers
* @module orders/order-purchase
* @author Lucie Zdeňková <lucie.zdenek@trustica.cz>
*/

import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { his_fetch, his_fetch_success, HisFetchStatus } from '../comp/FetchLoader';
import { Routes, Route } from "react-router-dom";
import { StockNotification } from './stock-notifications';
import { OrderPurchaseDetail } from './order-purchase-detail';
import { is_warehouse_defined } from "../lists/warehouses-defs";
import { make_checker } from '../comp/checker';
import { Card, Alert, Table, Button } from 'react-bootstrap';
import {
	check_product_hsh_adr, check_product_warehouse_adr, check_product_hsh_container, check_product_warehouse_container,
	package_weight_compare, check_card_in_warehouse, check_product_warehouse_values, check_product_hsh_density,
	check_product_hsh_amount, check_product_hsh_MJ, check_product_warehouse_density, check_product_wh_MJ, check_product_wh_amount,
	check_product_expiration, check_suppliers_addresses, check_suppliers_address_type, check_product_twist_approval, check_product_his_approval,
	check_product_batches_expirations_monitoring, check_duplicities, make_product_check_tag, check_package_weight_wh_values,
	check_NO_unit_uniformity, check_warehouse_existence, check_item_has_IDProduktu
} from '../products/product-checks';
import { get_package_weight_kg, check_package_weight_kg_input } from '../lib/generic-checks';
import { ErrorWrap } from '../comp/errorwrap';
import { SyncProblemHugeAlert } from '../comp/problems-card';
import { endNotification } from './stock-notifications-open';
import { useTranslation } from 'react-i18next';

//seen all - OK

/**
 * Finds duplicities in an array, returns duplicate items
 * 
 * @param {array} arr - array with items
 * @returns {array}
 */
export function findDuplicates(arr) {
	let sorted_arr = arr.slice().sort();
	let results = [];
	for (let i = 0; i < sorted_arr.length - 1; i++) {
		if (sorted_arr[i + 1] === sorted_arr[i]) {
			results.push(sorted_arr[i]);
		}
	}
	return results;
}

/**
 * Returns detail of order purchase -  so called *NO*
 * 
 * @param {any} userlogin - info about legged user
 * @returns {component}
 */
export function OrderPurchase({ userlogin }) {
	const { t } = useTranslation();

	const { code } = useParams();

	const [order, setOrder] = useState(null);
	const [items, setItems] = useState([]);
	const [products, setProducts] = useState([]);
	const [loadedStatus, setLoadedStatus] = useState(0);
	const [stockNotifi, setStockNotifi] = useState([]);
	const [emails, setEmails] = useState([]);
	const [texts, setTexts] = useState([]);
	const [suppliers, setSuppliers] = useState([]);
	const [notifyOtherUsers, setNotifyOtherUsers] = useState(null);
	const [stockNotifiLogs, setStockNotifiLogs] = useState([]);
	const [reloadingPeriod, setReloadingPeriod] = useState(0); //0 = no backgroud reloading, or milisecond

	const reloadIt = () => {
		setLoadedStatus(0);
		setOrder(null);
	}

	useEffect(() => {
		if (order === null) {
			const running_fetch = his_fetch(
				userlogin,
				[
					{
						uri: "/api/orders/purchase/" + code,
						json: true,
						status: setLoadedStatus,
						ok: function (resource, result) {
							//console.log(result);
							setOrder(result.order);
							//console.log(result.order);
							setItems(result.items);
							//console.log(result.items);
							setProducts(result.product); // stále to používáme v notifikacích, ve kroku 2
							//console.log(result.product);
							setStockNotifi(result.stock_notifications);
							setStockNotifiLogs(result.stock_notifications_logs);
							setEmails(result.emails);
							setTexts(result.texts);
							setSuppliers(result.supplier_data);
							setReloadingPeriod(5 * 1000);
						},
						error: function (resource, reason) {
							console.log('err: ' + reason);
							setOrder("error");
						}
					}
				]
			)
			return () => {
				running_fetch();
			}
		} if (notifyOtherUsers === null) {
			const fetch_users = his_fetch(
				userlogin,
				[
					{
						uri: '/api/stock-notifications/' + order.nod_IDDokladu + "/open",
						json: true,
						ok: function (resource, result) {
							// console.log(result);
							if (result.result === "ok") {
								setNotifyOtherUsers(result.users);
							}
						},
						error: function (resource, reason) {
							console.log('err: ' + reason);
						}
					}
				]
			);
			return () => {
				fetch_users();
			};
		} else {
			if (reloadingPeriod > 0) {
				//console.log("starting timeout");
				const timeout_id = setTimeout(() => {
					//console.log("tick tock");
					his_fetch(
						userlogin,
						[
							{
								uri: '/api/stock-notifications/' + order.nod_IDDokladu + "/open",
								json: true,
								ok: function (resource, result) {
									// console.log(result);
									if (result.result === "ok") {
										setNotifyOtherUsers(result.users);
									}
								},
								error: function (resource, reason) {
									console.log('err: ' + reason);
									setNotifyOtherUsers("error");
								}
							}
						]
					);
				}, reloadingPeriod);
				//console.log("timeout set");
				return () => {
					clearTimeout(timeout_id);
				};
			}
		}

	}, [userlogin, order, reloadingPeriod, code, notifyOtherUsers]);

	if (!his_fetch_success(loadedStatus)) {
		return <HisFetchStatus status={loadedStatus} loadingType="big" errorType="fetcherError" reloadButton={reloadIt} />;
	}

	const notifyOtherUsers_usable = (notifyOtherUsers === null || notifyOtherUsers === "error") ? [] : notifyOtherUsers;

	const { performCheck, ErrorBadge, TodoList, checksOK, worstCheckResult } = make_checker();

	check_suppliers_addresses(performCheck, "hsh", suppliers);
	check_suppliers_address_type(performCheck, "hsh", suppliers);

	const preprocessedItems = items.map(function (item) {

		const polozky_ids = items.map(item => item.nop_IDPolozky);
		const duplicities = findDuplicates(polozky_ids);
		check_duplicities(performCheck, item.nop_IDPolozky, duplicities);

		check_NO_unit_uniformity(performCheck, item.nop_IDPolozky, item.npj_HmotnostMj, item.npj_MjHmotnostiMj);

		check_product_twist_approval(performCheck, item.p_k_IDProduktu, item.approved_in_twist);
		check_product_his_approval(performCheck, item.p_k_IDProduktu, item.needs_approval, item.approved_in_his);
		const configured = parseInt(item.pwc_id || "0") > 0;
		check_product_batches_expirations_monitoring(performCheck, + item.p_k_IDProduktu, configured);
		check_product_expiration(performCheck, + item.p_k_IDProduktu, item.pwc_id, item.pwc_months);
		check_item_has_IDProduktu(performCheck, item.p_k_IDProduktu, item.p_k_IDProduktu);

		check_product_hsh_container(performCheck, "hsh-" + item.p_k_IDProduktu, item.co_k_HSHObal5);
		check_product_hsh_amount(performCheck, "hsh-" + item.p_k_IDProduktu, item.pjo_MnozstviSklMjVObalu);
		check_product_hsh_MJ(performCheck, "hsh-" + item.p_k_IDProduktu, item.p_KodMjSkl);
		check_product_hsh_density(performCheck, "hsh-" + item.p_k_IDProduktu, item.pj_HmotnostMj, item.pj_MjHmotnostiMj);
		check_product_hsh_adr(performCheck, "hsh-" + item.p_k_IDProduktu, item.p_k_ADR,
			item.kcunc_TridaADR, item.p_k_ADRObalovaSkupina, item.p_k_ADRUNCislo);

		// 666: "Počítání hmotnosti obalu v kg"
		//console.log(item);
		const [packageWeightInputsOK_HSH] = check_package_weight_kg_input(item.pj_HmotnostMj, item.npj_MjHmotnostiMj, item.pjo_MnozstviSklMjVObalu);
		const package_weight_kg_hsh = packageWeightInputsOK_HSH ? get_package_weight_kg(item.pj_HmotnostMj, item.npj_MjHmotnostiMj, item.pjo_MnozstviSklMjVObalu) : null;
		const [packageWeightInputsOK_wh] = check_package_weight_kg_input(item.pcr_HmotnostMjVKg, "kg", item.pcr_MnozstviSklMjVObalu); // "kg" protože ze skladu to nechodí
		const package_weight_kg_wh = packageWeightInputsOK_wh ? get_package_weight_kg(item.pcr_HmotnostMjVKg, "kg", item.pcr_MnozstviSklMjVObalu) : null;
		//const worth_display1 = item.pjo_MnozstviSklMjVObalu && item.pj_HmotnostMj;
		//const package_weight_kg_hsh_count = Fraction(item.pjo_MnozstviSklMjVObalu).mul(Fraction(item.pj_HmotnostMj));
		//const package_weight_kg_hsh = worth_display1 ? String(package_weight_kg_hsh_count) : <>&mdash;</>;
		//const worth_display2 = item.pcr_MnozstviSklMjVObalu && item.pcr_HmotnostMjVKg;
		//const package_weight_kg_wh_count = Fraction(item.pcr_MnozstviSklMjVObalu).mul(Fraction(item.pcr_HmotnostMjVKg));
		//const package_weight_kg_wh = worth_display2 ? String(package_weight_kg_wh_count) : <>&mdash;</>;
		const adr_whs = [16, "16", 22, "22"];
		const warehouse = item.nop_KodKnihySkladu ? item.nop_KodKnihySkladu.trim() : "nonsense";
		const adr_warehouse = adr_whs.includes(warehouse);
		const soFarSoGood = checksOK('hsh-' + item.p_k_IDProduktu + '$'); //kotroluju, jestli karetní hsh checky jsou ok

		check_warehouse_existence(performCheck, item.nop_KodKnihySkladu + "-" + item.p_k_IDProduktu, item.nop_KodKnihySkladu);
		if (item.pci_warehouse_id && soFarSoGood) { //item.pci_warehouse_id - product-card-imports -  indentiffikuje soubor, co poslali rano ze skladu, pokud je to null, tak produkt ve skladu není
			//console.log(item.pci_warehouse_id); //pokud nenavážem imporní soubor na kod knihy skladu je to null a neumime to kontrolovat, necháváme zelené
			//console.log(soFarSoGood);
			//	console.log(item.pci_warehouse_id && soFarSoGood);
			//	console.log(item.pci_warehouse_id);
			//	console.log(item.pcr_repeats);
			//	console.log("pre-missing-card-" + item.nop_KodKnihySkladu + "-" + item.p_k_IDProduktu);
			check_card_in_warehouse(performCheck, item.nop_KodKnihySkladu + "-" + item.p_k_IDProduktu, item.pcr_repeats);
			//	console.log("post-missing-card-" + item.nop_KodKnihySkladu + "-" + item.p_k_IDProduktu);
			if (checksOK("missing-card-" + item.nop_KodKnihySkladu + "-" + item.p_k_IDProduktu)) {
				//console.log(checksOK("missing-card-" + item.nop_KodKnihySkladu + item.p_k_IDProduktu));
				check_product_warehouse_values(performCheck, "wh-" + item.nop_KodKnihySkladu + "-" + item.p_k_IDProduktu, item.pcr_NazevProduktu, item.pcr_HmotnostMjVKg,
					item.pcr_MnozstviSklMjVObalu, item.pcr_KodMjSkl, true);
				// console.log("pre-missing-values-" + item.nop_KodKnihySkladu + item.p_k_IDProduktu);
				//	if (checksOK("missing-values-" + item.nop_KodKnihySkladu + "-" + item.p_k_IDProduktu)) {
				//console.log(checksOK("missing-values-" + item.nop_KodKnihySkladu + item.p_k_IDProduktu));
				check_product_warehouse_density(performCheck, "wh-" + item.nop_KodKnihySkladu + "-" + item.p_k_IDProduktu, item.pcr_HmotnostMjVKg, item.pj_HmotnostMj, true);
				check_product_wh_amount(performCheck, "wh-" + item.nop_KodKnihySkladu + "-" + item.p_k_IDProduktu, item.pcr_MnozstviSklMjVObalu, item.pjo_MnozstviSklMjVObalu, true);
				check_product_wh_MJ(performCheck, "wh-" + item.nop_KodKnihySkladu + "-" + item.p_k_IDProduktu, item.pcr_KodMjSkl, item.p_KodMjSkl, true);
				check_package_weight_wh_values(performCheck, "wh-" + item.nop_KodKnihySkladu + "-" + item.p_k_IDProduktu, item.pcr_MnozstviSklMjVObalu, item.pcr_HmotnostMjVKg);
				if (checksOK("missing-amount-or-density-" + item.nop_KodKnihySkladu + "-" + item.p_k_IDProduktu)) {
					package_weight_compare(performCheck, "wh-" + item.nop_KodKnihySkladu + "-" + item.p_k_IDProduktu, package_weight_kg_hsh, package_weight_kg_wh);
				}
				check_product_warehouse_container(performCheck, "wh-" + item.nop_KodKnihySkladu + "-" + item.p_k_IDProduktu, item.co_k_HSHObal5,
					item.nop_KodKnihySkladu, item.pcr_k_HSHObal5);
				check_product_warehouse_adr(performCheck, "wh-" + item.nop_KodKnihySkladu + "-" + item.p_k_IDProduktu, item.p_k_ADR,
					item.kcunc_TridaADR, item.p_k_ADRObalovaSkupina, item.p_k_ADRUNCislo,
					item.pcr_TridaADR, item.pcr_k_ADRObalovaSkupina, item.pcr_k_ADRUNCislo,
					!adr_warehouse);
			}
		}

		// kontroluje checky, který končej, -p_k_IDProduktu
		const item_checks_ok = checksOK('hsh-' + item.p_k_IDProduktu + '$') && checksOK("wh-" + item.nop_KodKnihySkladu + "-" + item.p_k_IDProduktu) &&
			checksOK("missing-card-" + item.nop_KodKnihySkladu + "-" + item.p_k_IDProduktu) && checksOK('' + item.p_k_IDProduktu + '$') && checksOK('' + item.nop_IDPolozky + '$');
		const bg_item_green_red = item_checks_ok ? "bg-success" : "bg-danger";
		const is_card = checksOK("missing-card-" + item.nop_KodKnihySkladu + "-" + item.p_k_IDProduktu);
		const redish = ((item.pcr_NazevProduktu || item_checks_ok || !soFarSoGood || item.pci_warehouse_id === null) ? "" : " bg-red");

		return {
			...item,
			package_weight_kg_hsh: package_weight_kg_hsh,
			package_weight_kg_wh: package_weight_kg_wh,
			bg_item_green_red: bg_item_green_red,
			is_card: is_card,
			redish: redish,
			item_checks_ok: item_checks_ok,
		}
	});
	//console.log(preprocessedItems);

	const everythingOK = checksOK();
	const items_config_warehouses = preprocessedItems.filter(item => is_warehouse_defined(item.nop_KodKnihySkladu));
	const noWarehouse = items_config_warehouses.length === 0;
	const notifi_able_items = items_config_warehouses.filter((item) => item.item_checks_ok);
	const supplier_ok = checksOK("addresses-hsh") && checksOK("address-hsh");
	const showButton = notifi_able_items.length > 0 && supplier_ok; //jsou dobře checky na supplier 
	const deliveryNO = order.nod_KodDokladu.slice(6, 8) === "10";
	//console.log(deliveryNO);

	const products_packagings_list = items.reduce(function (acc, it) {
		const id = it.p_k_IDProduktu;
		const obal = it.pjo_KodObalu;
		const current_list = acc[id] || [];
		return {
			...acc,
			[id]: current_list.concat([obal]).filter((v, i, a) => a.indexOf(v) === i).filter((v) => v !== null),
		}
	}, {});
	//console.log(products_packagings_list);
	/* eslint-disable */
	const products_packagings_counts = Object.keys(products_packagings_list).reduce(function (acc, id) { //const bude v budoucnu potřeba při řešení problému
		const len = products_packagings_list[id].length;
		acc[id] = len;
		performCheck(make_product_check_tag(id, "duppack"),
			"danger",
			function () {
				if (len === 0) {
					return false;
					// return "není obal";
				}
				if (len > 1) {
					return "multiple_basic_package_for_product";
				}
				return false;
			});
		return acc;
	}, {});
	//console.log(products_packagings_counts);

	if (order.twist_sync_problem) {
		return (
			<>
				<SyncProblemHugeAlert sentence={t('ord_purch_is_blocked')} />
				<br />
				<br />
				<OrderPurchaseDetail order={order} items={preprocessedItems} reloadIt={reloadIt}
					ErrorBadge={ErrorBadge} TodoList={TodoList} everythingOK={everythingOK}
					worstCheckResult={worstCheckResult}
					//showButton={showButton}  hidden so as not to show 
					noWarehouse={noWarehouse} deliveryNO={deliveryNO}
					userlogin={userlogin} stockNotifi={stockNotifi} emails={emails} texts={texts}
					notifyOtherUsers={notifyOtherUsers_usable} stockNotifiLogs={stockNotifiLogs} />
			</>
		);
	}

	return (
		<ErrorWrap>
			<Routes>
				<Route path="notification/:step" element={
					<StockNotification order={order} items={notifi_able_items} userlogin={userlogin}
						stockinsNumber={stockNotifi.length} products={products} reloadIt={reloadIt} texts={texts}
						notifyOtherUsers={notifyOtherUsers_usable} />
				} />
				<Route path="" element={
					<OrderPurchaseDetail order={order} items={preprocessedItems} reloadIt={reloadIt}
						ErrorBadge={ErrorBadge} TodoList={TodoList} everythingOK={everythingOK}
						worstCheckResult={worstCheckResult} showButton={showButton} noWarehouse={noWarehouse}
						userlogin={userlogin} stockNotifi={stockNotifi} emails={emails} texts={texts}
						notifyOtherUsers={notifyOtherUsers_usable} deliveryNO={deliveryNO}
						stockNotifiLogs={stockNotifiLogs} />
				}
				/>
			</Routes>
		</ErrorWrap >
	);
}

/**
 * Text to display during stockin process - "neutralizace" and such
 * 
 * @param {any} texts - array of texts to show
 * @returns {component}
 */
export function Texts({ texts }) {
	const { t } = useTranslation();

	var idx = 0;
	if (texts.length === 0) {
		return "";
	} else {
		return (
			<ErrorWrap>
				<Card className='mt-4 mb-4' style={{ maxWidth: "700px" }}>
					<Card.Body className='p-3'>
						<h5>{t('ord-texts')}</h5>
						<Table size="sm" borderless className="m-0">
							<tbody>
								{
									texts.map(function (t) {
										idx += 1;
										return <tr key={idx}>
											<td width="30%">{t.tnot_KodTypuTextu}</td>
											<td>{t.tnot_NazevTypuTextu}</td>
											{t.tnot_KodTypuTextu === "PRP14" ?
												<td>{t.tnot_TextText}</td>
												: false}
										</tr>
									})
								}
							</tbody>
						</Table>
					</Card.Body>
				</Card>
			</ErrorWrap>
		);
	}
}

/**
 * Alert 
 * 
 * @param {array} notifyOtherUsers
 * @param {any} userlogin
 * @param {function} reloadIt
 * @param {boolean} NOview 
 * @returns {component}
 */
export function OtherUsersAlert({ notifyOtherUsers, userlogin, reloadIt, NOview = false }) {
	const { t } = useTranslation();

	//console.log(notifyOtherUsers);
	const text = NOview ? t('ord-following_users_are_working_on_sn_within_this_order') + " " : t('ord-following_users_are_doing_this_sn_too') + " ";
	const authorizedUser = userlogin.userinfo.hisSuperuser === true || userlogin.userinfo.roleCompliance === true || userlogin.userinfo.roleCsrHead === true;
	const activeUsers = notifyOtherUsers.map((us) => us.uo_username);
	const filteredUsernames = activeUsers.filter(e => e !== userlogin.username);
	const filteredUsersSpaced = filteredUsernames.map((u) => " " + u);
	const intruder = filteredUsernames.length > 0;
	if (intruder) {
		return (
			<Alert variant="danger">
				{text} <b>{String(filteredUsersSpaced)}</b>. <br />
				{authorizedUser ?
					notifyOtherUsers.filter((user) => user.uo_username !== userlogin.username).map(function (user, idx) {
						return (
							<Button key={idx} onClick={() => endNotification(user.IDDokladu, user.opened_by, reloadIt, userlogin)} variant="dark" size="sm" className="me-2 mt-3">
								{t('ord-terminate_users_notification')} {user.uo_username}
							</Button>
						);
					})
					: ""
				}
			</Alert>
		);
	}
	return "";
}


//