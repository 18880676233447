/**
 * Products listing.
 * @module products/products
 * @author Dominik Pantucek <dominik.pantucek@trustica.cz>
 */
import React, { useState, useEffect } from 'react';
import { Row, Col, Table, Nav, Form } from 'react-bootstrap';
import { Pager } from '../comp/pager';
import { BooleanDropdown } from '../comp/boolean';
import { LinkContainer } from 'react-router-bootstrap';
import { Route, Routes } from "react-router-dom";
import { ProductDetail } from './product-detail';
import {
	his_fetch,
	his_fetch_loading,
	his_fetch_success,
	HisFetchStatus,
	TableFetchStatusOverlay
} from '../comp/FetchLoader';
import { ProductsTracking } from './products-tracking';
import { Messenger } from '../comp/messenger.js';
import { WarehousesPackagings } from './warehouses-packagings';
import { ErrorWrap } from '../comp/errorwrap';
import { ProductsCheckOverviewHorizontal } from './products-check-overview-horizontal';
import { ItemsAndFilteredPaged } from '../comp/dynamic-load';
import { StocksSwitch } from './stocks';
import { date_formatISO } from '../lib/date-utils';
import { NotesList } from '../notes/notes-list';
import { useTranslation } from 'react-i18next';
import { ChooseCategory } from '../comp/choose-category';
import {useDebounce} from "use-debounce";
import {ProductsCheckOverviewNew} from "./products-check-overview-new";

//seen all - OK

//         0               1                2              3          4                   5
//     KodProduktu    k_IDProduktu    NazevProduktu    k_ADR    KodPartneraDodavatel    Aktivni


export function Products({ user, userinfo, userlogin }) {
	const { t } = useTranslation();

	const defaultDate = date_formatISO(new Date());


	return (
		<ErrorWrap>
			<Nav variant="tabs" defaultActiveKey="/products/list" className="mb-3">
				<Nav.Item>
					<LinkContainer to="/products/list">
						<Nav.Link eventKey="/products/lists">{t('menu2-prod-list')}</Nav.Link>
					</LinkContainer>
				</Nav.Item>
				{/*<Nav.Item>
					<LinkContainer to="/products/check">
						<Nav.Link eventKey="/products/check">{t('menu2-prod-check')}</Nav.Link>
					</LinkContainer>
				</Nav.Item>*/}
				<Nav.Item>
					<LinkContainer to="/products/check/new">
						<Nav.Link eventKey="/products/check/new">{t('menu2-prod-check')}</Nav.Link>
					</LinkContainer>
				</Nav.Item>
				<Nav.Item>
					<LinkContainer to="/products/tracking">
						<Nav.Link eventKey="/products/tracking">{t('menu2-prod-track')}</Nav.Link>
					</LinkContainer>
				</Nav.Item>
				<Nav.Item>
					<LinkContainer to="/products/packagings">
						<Nav.Link eventKey="/products/packagings">{t('menu2-prod-pack')}</Nav.Link>
					</LinkContainer>
				</Nav.Item>
				<Nav.Item>
					<LinkContainer to={"/products/stocks/" + defaultDate}>
						<Nav.Link eventKey={"/products/stocks/" + defaultDate}>{t('menu3-deno-stat-wh')}</Nav.Link>
					</LinkContainer>
				</Nav.Item>
				<Nav.Item>
					<LinkContainer to="/products/notes">
						<Nav.Link eventKey="/products/notes">{t('menu2-notes')}</Nav.Link>
					</LinkContainer>
				</Nav.Item>
				<Nav.Item className="ms-auto">
					<Routes>
						<Route path=':id/view' element={<Messenger userlogin={userlogin} type={'prod'} />} />
						<Route path='stocks/:fetchDate/inventory/:wh/*' element={<Messenger userlogin={userlogin} type={'inventory'} />} />
					</Routes>
				</Nav.Item>


			</Nav>

			<Routes>
				<Route path='list' element={
					<ProductsList user={user} userinfo={userinfo} userLogin={userlogin}/>
				} />
				{/*<Route path='check' element={<ProductsCheckOverviewHorizontal userlogin={userlogin} />} />*/}
				<Route path='check/new' element={<ProductsCheckOverviewNew userlogin={userlogin} />} />
				<Route path=':id/view' element={<ProductDetail user={user} userinfo={userinfo} userlogin={userlogin} />} />
				<Route path='tracking' element={<ProductsTracking user={user} userinfo={userinfo} userlogin={userlogin} />} />
				<Route path='packagings' element={<WarehousesPackagings />} />
				<Route path='stocks/:fetchDate/*' element={<StocksSwitch userlogin={userlogin} />} />
				<Route path="notes" element={<NotesList userlogin={userlogin} diff_object_types={{ 1: false, 2: false, 3: true, 4: true }} />} />
				<Route path="" element={<ChooseCategory />} />
			</Routes>
		</ErrorWrap>
	);
}

function filtersEqual(prev, next) {
	return prev.productId === next.productId && prev.productCode === next.productCode
		&& prev.productName === next.productName && prev.supplierPartnerCode === next.supplierPartnerCode
		&& prev.adr === next.adr && prev.active === next.active && prev.massFilter === next.massFilter;
}

function ProductsList({ userLogin }) {
	const pageSize = 20;
	
	const [products, setProducts] = useState(null);
	const [offset, setOffset] = useState(0);
	const [filterCode, setFilterCode] = useState(undefined);
	const [filterID, setFilterID] = useState(undefined);
	const [filterName, setFilterName] = useState(undefined);
	const [filterPartner, setFilterPartner] = useState(undefined);
	const [filterADR, setFilterADR] = useState(undefined);
	const [filterAktivni, setFilterAktivni] = useState(undefined);
	const [massFilter, setMassFilter] = useState(undefined);
	const [loadedStatus, setLoadedStatus] = useState(0);
	const [filter, setFilter] = useState({});
	
	const [debouncedFilter, {flush}] = useDebounce(filter, 500, {equalityFn: filtersEqual});

	useEffect(() => {
		setFilter({
			productCode: filterCode,
			productId: filterID,
			productName: filterName,
			supplierPartnerCode: filterPartner,
			adr: filterADR,
			active: filterAktivni,
			massFilter: massFilter
		});
	}, [filterCode, filterID, filterName, filterPartner, filterADR, filterAktivni, massFilter]);
	
	const reloadIt = () => {
		setProducts(null);
		setLoadedStatus(0);
	}

	useEffect(() => {
		const running_fetch = his_fetch(
			userLogin,
			[
				{
					uri: `/api/products?take=${pageSize}&skip=${offset}&filter=${encodeURIComponent(JSON.stringify(debouncedFilter))}`,
					json: true,
					status: setLoadedStatus,
					ok: function (resource, result) {
						setProducts(result);
					},
					error: function (resource, reason) {
						console.log('err: ' + reason);
						setProducts(undefined);
					}
				}
			]
		);
		return () => {
			running_fetch();
		}
	}, [userLogin, setLoadedStatus, setProducts, offset, debouncedFilter]);

	const cleanFilters = () => {
		setFilterCode("");
		setFilterID("");
		setFilterName("");
		setFilterPartner("");
		setFilterADR(null);
		setFilterAktivni(null);
		setMassFilter("");
		flush();
	}

	if (!products && !his_fetch_success(loadedStatus)) {
		return <HisFetchStatus status={loadedStatus} loadingType="big" errorType="fetcherError" reloadButton={reloadIt} />;
	}

	const handleChangeCode = function (event) {
		setFilterCode(event.target.value);
		setOffset(0);
	}

	const handleChangeID = function (event) {
		setFilterID(event.target.value);
		setOffset(0);
	}

	const handleChangeName = function (event) {
		setFilterName(event.target.value);
		setOffset(0);
	}

	const handleChangePartner = function (event) {
		setFilterPartner(event.target.value);
		setOffset(0);
	}
	const handleChangeMassFilter = function (event) {
		setMassFilter(event.target.value);
		setOffset(0);
	}
    
	return (
		<ErrorWrap>
			<Row>
				<Col xs="12" sm="0" md="4"></Col>
				<Col xs="12" sm="6" md="4" className='text-center mb-1'>
					<ItemsAndFilteredPaged filteredCount={products.filteredCount} totalCount={products.totalCount} cleanFilters={cleanFilters} />
				</Col>
				<Col xs="12" sm="6" md="4" >
					<Pager offset={offset} pagesize={pageSize} total={products.filteredCount} callback={setOffset} disabled={his_fetch_loading(loadedStatus)} />
				</Col>
			</Row>
			<TableFetchStatusOverlay status={loadedStatus}>
                <ProductsWeb
                    filterID={filterID} handleChangeID={handleChangeID} filterCode={filterCode} handleChangeCode={handleChangeCode} filterName={filterName}
                    handleChangeName={handleChangeName} setFilterADR={setFilterADR} filterADR={filterADR}
                    filterPartner={filterPartner} handleChangePartner={handleChangePartner} setFilterAktivni={setFilterAktivni}
                    filterAktivni={filterAktivni} show_products={products.items} loadedStatus={loadedStatus}
                />
                <ProductsApp
                    setFilterADR={setFilterADR} filterADR={filterADR}
                    setFilterAktivni={setFilterAktivni} filterAktivni={filterAktivni}
                    show_products={products.items} massFilter={massFilter} handleChangeMassFilter={handleChangeMassFilter}
                />
			</TableFetchStatusOverlay>
			<Pager offset={offset} pagesize={pageSize} total={products.filteredCount} callback={setOffset} disabled={his_fetch_loading(loadedStatus)} />
		</ErrorWrap>
	);
}

function ProductsApp({ setFilterADR, filterADR, setFilterAktivni, filterAktivni, show_products, massFilter, handleChangeMassFilter }) {
	const { t } = useTranslation();
	return (
		<div className="d-lg-none">
			<Form.Group controlId="filterMass">
				<Form.Label className='mb-1'>{t('mass_filter')}</Form.Label>
				<Form.Control type="text" className="mb-3"
					placeholder={"🔍 " + t('filter_id_code_name_supplier')}
					value={massFilter}
					onChange={handleChangeMassFilter} />
			</Form.Group>
			<Row>
				<Col className='text-center'>
					{t('active')}
					<BooleanDropdown onChange={setFilterAktivni} variant="onlyTrue" value={filterAktivni} className="beGrayButton" />
				</Col>
				<Col className='text-center'>
					{t('prod-ADR')}
					<BooleanDropdown onChange={setFilterADR} variant="onlyTrue" value={filterADR} className="beGrayButton" />
				</Col>
			</Row>
			<Table size="sm" striped className="mt-3" >
				<tbody>
					{show_products.map((row) => <ProductsRowApp row={row} key={row.productCode} />)}
				</tbody>
			</Table>
		</div>
	);
}

function ProductsRowApp({ row }) {
	const { t } = useTranslation();

	return (
		<LinkContainer to={{ pathname: "/products/" + encodeURIComponent(row.productCode) + "/view" }} key={row.productCode}>
			<tr>
				<td>
					<Row>
						<Col xs="auto">{row.productId}</Col>
						<Col className="text-end">{t('active')}: {row.active ? <span role="img" aria-label="ano">Ano &#9989;</span> : "Ne"} </Col>
					</Row>
					<Row>
						<Col xs="auto">{row.productCode}</Col>
						<Col className="text-end">{t('prod-ADR')}: {row.adr ? <span role="img" aria-label="ano">Ano &#9989;</span> : "Ne"}</Col>
					</Row>
					<Row>
						<Col xs="auto">{row.productName}</Col>
						<Col className="text-end">{t('prod-bbd_batch_settings')}.: {row.warehouseConfiguration?.type}</Col> {/**tady bude potřeba sjednocení */}
					</Row>
					<Row>
						<Col xs="auto">{t('prod-supplier')}: {row.supplierPartnerCode}</Col>
						<Col className="text-end">{row.warehouseConfiguration?.months ? <>{t('months')}: {row.warehouseConfiguration?.months}</> : ""}</Col>
					</Row>
				</td>
			</tr>
		</LinkContainer>
	);
}

function ProductsWeb({ filterID, handleChangeID, filterCode, handleChangeCode, filterName, handleChangeName, setFilterADR, filterADR,
	filterPartner, handleChangePartner, setFilterAktivni, filterAktivni, show_products, loadedStatus }) {
	const { t } = useTranslation();
	return (
		<Table className='d-none d-lg-table' hover size="sm" striped>
			<thead className="beGray">
			<tr>
				<th rowSpan="2" className='pb-3'>
					<Form.Group controlId="filterID">
						<Form.Control type="text"
									  placeholder={"🔍 " + t('not-prod_id')}
									  value={filterID}
									  onChange={handleChangeID}/>
					</Form.Group>
				</th>
				<th rowSpan="2" className='pb-3'>
					<Form.Group controlId="filterCode">
						<Form.Control type="text"
									  placeholder={"🔍 " + t('prod-code')}
									  value={filterCode}
									  onChange={handleChangeCode}/>
					</Form.Group>
				</th>
				<th rowSpan="2" className='pb-3'>
					<Form.Group controlId="filterName">
						<Form.Control type="text"
									  placeholder={"🔍 " + t('prod-name')}
									  value={filterName}
									  onChange={handleChangeName}/>
					</Form.Group></th>
				<th rowSpan="2" className="text-center pb-3" style={{paddingBottom: "21px"}}>{t('prod-ADR')}
					<BooleanDropdown onChange={setFilterADR} variant="onlyTrue" value={filterADR}/>
				</th>
				<th rowSpan="2" className='pb-3'>
					<Form.Group controlId="filterPartner">
						<Form.Control type="text"
									  placeholder={"🔍 " + t('prod-supplier_partner')}
									  value={filterPartner}
									  onChange={handleChangePartner}/>
					</Form.Group></th>
				<th rowSpan="2" className="text-center pb-3" style={{paddingBottom: "21px"}}>{t('active')}
					<BooleanDropdown onChange={setFilterAktivni} variant="onlyTrue" value={filterAktivni}/>
				</th>
				<th colSpan="2" className="text-center">{t('prod-batch_expiry')}</th>
			</tr>
			<tr>
				<th className="text-center pb-2">{t('prod-settings')}</th>
				<th className="text-center pb-2">{t('months')}</th>
			</tr>
			</thead>
			<tbody>
			{
				show_products.map(function (pr) {
					return (
						<LinkContainer key={pr.productCode}
									   to={{pathname: "/products/" + encodeURIComponent(pr.productCode) + "/view"}}>
							<tr>
								<td width="10%" className="align-middle">{pr.productId}</td>
								<td width="20%" className="align-middle">{pr.productCode}</td>
								<td width="30%" className="align-middle">{pr.productName}</td>
								<td width="5%" className="text-center align-middle">{pr.adr ?
									<span role="img" aria-label="ano">{t('yes')} &#9989;</span> : t('no')}</td>
								<td width="15%" className="align-middle">{pr.supplierPartnerCode}</td>
								<td width="5%" className="text-center align-middle">{pr.active ?
									<span role="img" aria-label="ano">{t('yes')} &#9989;</span> : t('no')}</td>
								<td width="5%" className="text-center">{pr.warehouseConfiguration?.type}</td>
								<td width="5%" className="text-end pe-3">{pr.warehouseConfiguration?.months}</td>
							</tr>
						</LinkContainer>
					);
				})
			}
			</tbody>
		</Table>
	);
}
