/**
* Detail of order purchase 
* @module orders/order-purchase-detail
* @author Lucie Zdeňková <lucie.zdenek@trustica.cz>
*/
import React, { useState } from 'react';
import { Table, Row, Col, Image, Button, Card } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { OkOrProblemsCardNO } from '../comp/problems-card';
import { NO_PIN_generate } from "../comp/pin";
import { make_product_check_tag } from '../products/product-checks';
import { DensityAmountUnitWeightView, ProductContainerView, ProductADRView } from '../products/product-view';
import { Emails } from '../emails';
import { Texts, OtherUsersAlert } from './order-purchase';
import { ErrorWrap } from '../comp/errorwrap';
import { MdExpandMore, MdExpandLess } from 'react-icons/md';
import { final_states_SN } from './progress-stockins-def';
import { Downloader } from '../comp/downloader';
import { partnerName } from '../lists/warehouses-defs';
import { NotificationsTableHead, NotificationsOfOrderPurchase, get_id_boolean, stockin_data_transformation } from './stockin-display-comms';
import { ItemsofNOPurchaseApp } from './order-purchase-detail-app';
import { useTranslation } from 'react-i18next';

//seen all - OK

function expandAll(allHidden, setDetailShown, stockNotifi) {
    const value = allHidden ? true : false;
    setDetailShown(get_id_boolean(stockNotifi, value));
}

export function OrderPurchaseDetail({ order, items, reloadIt,
    ErrorBadge, TodoList, worstCheckResult, emails, texts,
    everythingOK, showButton, noWarehouse, userlogin, stockNotifi,
    notifyOtherUsers, deliveryNO, stockNotifiLogs }) {
    const { t } = useTranslation();

    const authorizedUser = userlogin.userinfo.hisSuperuser === true || userlogin.userinfo.roleCompliance === true || userlogin.userinfo.roleCsrHead === true;

    const remaining = items.map((item) => item.nop_ZbyvaPrijmout);
    var allNotifiedNO = remaining.reduce((partial_sum, a) => partial_sum + a, 0) === 0;

    const currentStockNotifi = stockNotifi.filter(noti => (!final_states_SN.includes(noti.sns_status)));
    const NOs = stockin_data_transformation(stockNotifi, stockNotifiLogs);
    const NOs_final = NOs.length > 0 ? NOs[0].notifications : [];
    const current_NOs = stockin_data_transformation(currentStockNotifi, stockNotifiLogs);
    const current_NOs_final = current_NOs.length > 0 ? current_NOs[0].notifications : [];

    return (
        <ErrorWrap>
            <div className='mb-3'>
                <Button size="sm" className="me-2 d-inline" onClick={reloadIt}><Image src="/img/reload.svg" height="19" /></Button>
                <h3 className="d-inline align-middle">{t('ord-pur')} {order.nod_KodDokladu} |
                    <span >&nbsp;{t('partner')}&nbsp;</span>
                    <LinkContainer to={{ pathname: "/partners/list/" + order.nod_KodPartnera + "/orders/purchase" }}>
                        <a href="!#">{order.nod_KodPartnera}</a>
                    </LinkContainer>
                    <ErrorBadge tag={make_product_check_tag("hsh", "addresses")} />
                    <ErrorBadge tag={make_product_check_tag("hsh", "address")} />
                </h3>
            </div>
            <Row>
                <Col xs={12} xl={8}>
                    {authorizedUser ?
                        <Card className='mt-4 mb-4'>
                            <Card.Body className='p-3'>
                                <Row>
                                    <Col>
                                        <h5 className='d-inline mb-0'>{t('ord-cards')}</h5>
                                    </Col>
                                    {["GW", "LF", "JP"].map((ww, idx) =>
                                        <Col key={idx}>
                                            <Downloader img="/application-excel.svg" alt="xlsx" height="25"
                                                label={partnerName[ww]}
                                                path={"/api/orders/purchase/" + order.nod_KodDokladu + "/card/" + ww}
                                                filename={"karty-" + order.nod_KodDokladu + "-" + ww + ".xlsx"} />
                                        </Col>
                                    )}
                                </Row>
                            </Card.Body>
                        </Card>
                        : <></>}
                    <SNOverview stockNotifi={currentStockNotifi} notifications={current_NOs_final} defaultExpanded
                        currentSN authorizedUser={authorizedUser} userlogin={userlogin} reloadIt={reloadIt}
                        showDaysToEtaCol />
                </Col>
                <Col xs={12} xl={4}>
                    <OtherUsersAlert notifyOtherUsers={notifyOtherUsers} userlogin={userlogin} reloadIt={reloadIt} NOview />
                    <OkOrProblemsCardNO NO={order.nod_KodDokladu} ok={everythingOK} headerOk={t('ord-you_can_notify')}
                        textOk={t('ord-cards_ok_you_can_notify')}
                        todo={<TodoList />} variant={worstCheckResult()} deliveryNO={deliveryNO}
                        showButton={showButton} noWarehouse={noWarehouse} allNotifiedNO={allNotifiedNO} />
                </Col>
            </Row>
            <ItemsofNOPurchase items={items} authorizedUser={authorizedUser} ErrorBadge={ErrorBadge} />
            <Texts texts={texts} />
            <SNOverview stockNotifi={stockNotifi} notifications={NOs_final}
                authorizedUser={authorizedUser} userlogin={userlogin} reloadIt={reloadIt} />
            {emails.length > 0 ?
                <>
                    <Card className='mt-4 mb-4'>
                        <Card.Body className='px-0 pb-0'>
                            <Emails userlogin={userlogin} pagesize={5} existingEmails={emails} noFetch optionalHeader={t('ord-no_emails') + " " + order.nod_KodDokladu} />
                        </Card.Body>
                    </Card>
                </>
                : ""}
        </ErrorWrap>
    );
}

function ItemsofNOPurchase({ items, authorizedUser, ErrorBadge }) {
    const { t } = useTranslation();
    return (
        <>
            <div className='d-none d-xl-block'>
                <ItemsofNOPurchaseWeb items={items} authorizedUser={authorizedUser} ErrorBadge={ErrorBadge} />
            </div>
            <div className='d-xl-none'>
                <Card className='m-0 p-0'>
                    <Card.Body className='m-0 p-1'>
                        <h3 className='mt-2 ms-2'>{t('biz-items')}</h3>
                        {items.map(function (item, idx) {
                            return <ItemsofNOPurchaseApp key={idx} item={item} authorizedUser={authorizedUser} ErrorBadge={ErrorBadge} />;
                        })}
                    </Card.Body>
                </Card>
            </div>
        </>
    );
}

/**
 * Overview of stockins at NO with expand button, its used twice - for currently processed and for history card
 * 
 * @param {boolean} showDaysToEtaCol - display column of remaining days to eta
 * @returns {component}
 */
export function SNOverview({ stockNotifi, notifications, currentSN = false, defaultExpanded = false,
    authorizedUser, userlogin, reloadIt, showDaysToEtaCol }) {
    const { t } = useTranslation();

    const expandedDetailShown = stockNotifi.map(sn => sn.sn_id).filter((v, i, a) => a.indexOf(v) === i).reduce((acc, v) => ({ ...acc, [v]: true }), {});
    const rightDetailShown = defaultExpanded ? expandedDetailShown : {};
    const [detailShown, setDetailShown] = useState(rightDetailShown);

    function toggleDetailShown(sn_id) {
        setDetailShown({ ...detailShown, [sn_id]: !detailShown[sn_id] }); //dej tam opačnou hodnotu, než jakou má aktuální na sn_id
    }

    const header = currentSN ? t('ord-stockin_in_process') : t('ord-stockin_history');
    const emptyMessage = currentSN ? t('ord-no_stockins_currently') : t('ord-no_stockins_ever');
    const allHidden = Object.keys(detailShown).length === Object.values(detailShown).filter((v) => v === false).length;

    return (
        <Card className='mt-4 mb-4'>
            <Card.Body className='px-0 pb-0'>
                <h5 className="mb-3 ps-3 d-inline">{header}</h5>
                {notifications.length === 0 ? "" :
                    <Button size="sm" className='float-end mb-3 me-4' onClick={() => expandAll(allHidden, setDetailShown, stockNotifi)}>
                        {allHidden ? <>{t('expand')} <MdExpandMore /></> : <>{t('hide')} <MdExpandLess /></>}
                    </Button>
                }
                {stockNotifi.length === 0 ?
                    <p className='text-muted ps-3'>{emptyMessage}</p>
                    :
                    <Table className='border mb-0' size="sm" >
                        <NotificationsTableHead showDaysToEtaCol={showDaysToEtaCol} />
                        <tbody >
                            <NotificationsOfOrderPurchase key={currentSN ? 1 : 2} notifications={notifications} detailShown={detailShown} toggleDetailShown={toggleDetailShown}
                                authorizedUser={authorizedUser} userlogin={userlogin} reloadIt={reloadIt} showDaysToEtaCol={showDaysToEtaCol} />
                        </tbody>
                    </Table>
                }
            </Card.Body>
        </Card>
    );
}

/**
 * Table of items for desktop.
 * 
 * @param {any} items - items in order purchase
 * @param {boolean} authorizedUser - if true, PIN is also displayed
 * @param {any} ErrorBadge - component showing error numbers at right places
 * @returns {component}
 */
export function ItemsofNOPurchaseWeb({ items, authorizedUser, ErrorBadge }) {
    const { t } = useTranslation();
    return (
        <ErrorWrap>
            <Card className='mt-4 mb-4'>
                <Card.Body className='px-0 pb-0'>
                    <h3 className="mb-2 ms-3">{t('biz-items')}</h3>
                    <Table size="sm" className="striped-2-rows mb-0">
                        <ItemsofNOPurchaseHeaderWeb authorizedUser={authorizedUser} />
                        <tbody>
                            {
                                items.map(function (item) {
                                    return (
                                        <React.Fragment key={item.nop_IDPolozky} >
                                            <tr key={item.nop_KodProduktu}>
                                                <td rowSpan="2" className={item.bg_item_green_red}></td>
                                                <td rowSpan="2" className="align-middle">{item.p_k_IDProduktu}
                                                    <ErrorBadge tag={make_product_check_tag(item.p_k_IDProduktu, "IDProduktu-missing")} />
                                                    <ErrorBadge tag={make_product_check_tag(item.nop_IDPolozky, "duplicity")} />
                                                </td>
                                                {authorizedUser ?
                                                    <td rowSpan="2" className="align-middle">{item.nop_IDPolozky}</td>
                                                    : false}
                                                <td rowSpan="2" className="align-middle">
                                                    <LinkContainer to={{ pathname: "/products/" + encodeURIComponent(item.nop_KodProduktu) + "/view" }}>
                                                        <a href="!#">{item.nop_KodProduktu}</a>
                                                    </LinkContainer>
                                                </td>
                                                <td rowSpan="2" className="align-middle text-end">{item.nop_Mnozstvi}&nbsp;{item.nop_KodMj}
                                                <ErrorBadge tag={make_product_check_tag(item.nop_IDPolozky, "NO-unit-uniformity")} />
                                                </td>
                                                <td rowSpan="2" className="align-middle text-center">{item.nop_Prijato}&nbsp;{item.nop_KodMj}</td>
                                                <td rowSpan="2" className="align-middle text-center">{item.nop_ZbyvaPrijmout}&nbsp;{item.nop_KodMj}</td>
                                                <td className="border-start">HSH:
                                                    <ErrorBadge tag={make_product_check_tag(item.p_k_IDProduktu, "twistApproval")} />
                                                    <ErrorBadge tag={make_product_check_tag(item.p_k_IDProduktu, "hisApproval")} />
                                                    <ErrorBadge tag={make_product_check_tag(item.p_k_IDProduktu, "configured")} />
                                                    <ErrorBadge tag={make_product_check_tag(item.p_k_IDProduktu, "monthExpiration")} />
                                                </td>
                                                <td>{item.nop_NazevProduktu ? item.nop_NazevProduktu : <>&mdash;</>}</td>
                                                <ProductContainerView id={"hsh-" + item.p_k_IDProduktu}
                                                    container={item.co_k_HSHObal5}
                                                    ErrorBadge={ErrorBadge} border />
                                                <DensityAmountUnitWeightView
                                                    id={"hsh-" + item.p_k_IDProduktu} density={item.pj_HmotnostMj}
                                                    densityUnit={item.pj_MjHmotnostiMj} amount={item.pjo_MnozstviSklMjVObalu}
                                                    unit={item.p_KodMjSkl} packageWeight={item.package_weight_kg_hsh}
                                                    ErrorBadge={ErrorBadge} border
                                                />
                                                <ProductADRView id={"hsh-" + item.p_k_IDProduktu}
                                                    TridaADR={item.kcunc_TridaADR}
                                                    k_ADRObalovaSkupina={item.p_k_ADRObalovaSkupina}
                                                    k_ADRUNCislo={item.p_k_ADRUNCislo}
                                                    ErrorBadge={ErrorBadge} />
                                                {authorizedUser ?
                                                    <td rowSpan="2" className="border-start align-middle text-center">{NO_PIN_generate(item.nop_IDPolozky, 1)}</td>
                                                    : false}
                                            </tr>
                                            <tr>
                                                <td className="border-start">
                                                    {item.nop_KodKnihySkladu ? <>{item.nop_KodKnihySkladu}:</> : ""}
                                                    <ErrorBadge tag={make_product_check_tag(item.nop_KodKnihySkladu + "-" + item.p_k_IDProduktu, "missing-card")} />
                                                    <ErrorBadge tag={make_product_check_tag(item.nop_KodKnihySkladu + "-" + item.p_k_IDProduktu, "missing-values")} />
                                                    <ErrorBadge tag={make_product_check_tag(item.nop_KodKnihySkladu + "-" + item.p_k_IDProduktu, "wh-existance")} />
                                                </td>
                                                <td className={item.redish}>
                                                    {item.pcr_NazevProduktu ? item.pcr_NazevProduktu : <>&mdash;</>}
                                                </td>
                                                <ProductContainerView id={"wh-" + item.nop_KodKnihySkladu + "-" + item.p_k_IDProduktu}
                                                    wid={item.nop_KodKnihySkladu}
                                                    container={item.pcr_k_HSHObal5}
                                                    remap border
                                                    ErrorBadge={ErrorBadge}
                                                    bg={!item.is_card} />
                                                <DensityAmountUnitWeightView
                                                    id={"wh-" + item.nop_KodKnihySkladu + "-" + item.p_k_IDProduktu} density={item.pcr_HmotnostMjVKg}
                                                    densityUnit="kg" amount={item.pcr_MnozstviSklMjVObalu}
                                                    unit={item.pcr_KodMjSkl} packageWeight={item.package_weight_kg_wh}
                                                    ErrorBadge={ErrorBadge} bg={!item.is_card} border
                                                />
                                                <ProductADRView id={"wh-" + item.nop_KodKnihySkladu + "-" + item.p_k_IDProduktu}
                                                    TridaADR={item.pcr_TridaADR}
                                                    k_ADRObalovaSkupina={item.pcr_k_ADRObalovaSkupina}
                                                    k_ADRUNCislo={item.pcr_k_ADRUNCislo}
                                                    romanized={true}
                                                    ErrorBadge={ErrorBadge}
                                                    bg={!item.is_card} />
                                            </tr>
                                        </React.Fragment>
                                    );
                                })
                            }
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        </ErrorWrap>
    );
}

/**
 * Desktop header for items in order purchase detail
 * 
 * @param {boolean} authorizedUser - if true, PIN is also displayed
 * @returns {component}
 */
function ItemsofNOPurchaseHeaderWeb({ authorizedUser }) {
    const { t } = useTranslation();
    return (
        <thead className="beGray">
            <tr>
                <th rowSpan="3" className='ps-3'>#</th>
                <th rowSpan="3">{t('prod-id')}</th>
                {authorizedUser ?
                    <th rowSpan="3" >{t('ord-item_id')}</th>
                    : false}
                <th rowSpan="3">{t('code')}</th>
                <th rowSpan="3" className="text-end">{t('ord-amount_measure_unit')}</th>
                <th rowSpan="3" className="text-center">{t('ord-accepted_by_wh')}</th>
                <th rowSpan="3" className="text-center">{t('ord-remaining')}</th>
                <th colSpan={9} className='text-center border-start'>{t('prod-prod_card')} <small>({t('prod-hsh_wh_comparison')})</small></th>
                {authorizedUser ?
                    <th rowSpan="3" className="border-start text-center">PIN</th>
                    : false}
            </tr>
            <tr>
                <th className="border-start" rowSpan={2}>{t('warehouse')}</th>
                <th rowSpan={2}>{t('name')}</th>
                <th colSpan="4" className="text-center border-end border-start">{t('prod-package')}</th>
                <th colSpan="3" className="text-center border-end">{t('prod-ADR')}</th>
            </tr>
            <tr className='beGray'>
                <th className="text-center beGray border-start">{t('ord-pack')}</th>
                <th className="text-end beGray">{t('ord-content_w_unit')}</th>
                <th className="text-end beGray">{t('ord-density')}</th>
                <th className="text-end beGray border-end">{t('ord-1_pack_weight')}</th>
                <th className="text-center beGray">{t('prod-class')}</th>
                <th className="text-center beGray">{t('prod-pack-group')}</th>
                <th className="text-center beGray border-end">{t('ord-un_num')}</th>
            </tr>
        </thead>
    )
}
