/**
 * Generic pager component
 * @module comp/pager
 * @author Dominik Pantucek <dominik.pantucek@trustica.cz>
 */
import React, { useEffect } from 'react';
import Pagination from 'react-bootstrap/Pagination';

//seen all - OK

/**
 * Pagination component.
 * 
 * @param {number} offset - start of pager
 * @param {number} pagesize - items per one page
 * @param {number} total - number of all items
 * @param {function} callback - function that updates offset value
 * @param {boolean} debug - shows additional debugging info
 * @param {string} className - additional styling
 * @param {boolean} disabled - disable the pager
 * @returns {component}
 */
export function Pager({ offset, pagesize, total, callback, debug = false, className, disabled }) {

	useEffect(() => {
        if (offset < 0) {
            callback(0);
        } else if (total > 0 && offset > total) {
            callback(total - (total % pagesize));
        }
    }, [total, offset, pagesize, callback]);
	//patří sem return fukce? toto není asynchnní subs

	var pager = [];
	var id = 1;
	// Beginning of the pager
	if (offset > 0) {
		// add prev and first link
		const firstClick = function () {
			callback(0);
		}
		pager.push(<Pagination.First disabled={disabled} key={id} onClick={firstClick} />);
		id += 1;
		const prevClick = function () {
			callback(offset - pagesize);
		}
		pager.push(<Pagination.Prev disabled={disabled} key={id} onClick={prevClick} />);
		id += 1;
	} else {
		// add disabled links
		pager.push(<Pagination.First key={id} disabled />);
		id += 1;
		pager.push(<Pagination.Prev key={id} disabled />);
		id += 1;
	}

	// Main pages section with elipsis
	var poffset = 0;
	var dots = false;
	var pagenum = 1;
	while (poffset < total) {
		if ((poffset <= pagesize) ||
			(poffset >= total - 2 * pagesize + 1) ||
			(Math.abs(poffset - offset) <= pagesize)) {
			// Regural page
			dots = false;
			if (poffset === offset) {
				pager.push(<Pagination.Item key={id} active>{pagenum}</Pagination.Item>);
				id += 1;
			} else {
				const local_poffset = poffset;
				const pageClick = function () {
					callback(local_poffset);
				}
				pager.push(<Pagination.Item disabled={disabled} key={id} onClick={pageClick}>{pagenum}</Pagination.Item>);
				id += 1;
			}
		} else {
			// Ellipsis
			if (!dots) {
				pager.push(<Pagination.Ellipsis key={id} disabled />);
				id += 1;
			}
			dots = true;
		}

		// Next page
		poffset += pagesize;
		pagenum += 1;
	}

	// Tail
	if (offset < total - pagesize) {
		const nextpg = offset + pagesize;
		const nextClick = function () {
			callback(nextpg);
		}
		pager.push(<Pagination.Next disabled={disabled} key={id} onClick={nextClick} />);
		id += 1;
		const lastpg = Math.floor(total / pagesize) * pagesize;
		const lastClick = function () {
			callback(lastpg);
		}
		pager.push(<Pagination.Last disabled={disabled} key={id} onClick={lastClick} />);
		id += 1;
	} else {
		pager.push(<Pagination.Next key={id} disabled />);
		id += 1;
		pager.push(<Pagination.Last key={id} disabled />);
		id += 1;
	}
	var debugEl = <></>;
	if (debug) {
		debugEl = (
			<p>
				offset = {offset}<br />
				pagesize = {pagesize}<br />
				total = {total}
			</p>
		);
	}

	return (
		<>
			<Pagination size="sm" className={`${className} d-flex float-end`}>
				{pager}
			</Pagination>
			{debugEl}
		</>
	);
}
