/**
 * Dynamic loading management for faster loading of long lists.
 * @module comp/dynamic-load
 * @author Dominik Pantucek <dominik.pantucek@trustica.cz>
 */

import React, { useState, useEffect, useRef } from 'react';
import { date_getTomorrow, date_formatISO, date_isLastInMonth } from '../lib/date-utils';
import { Button, Image, Row, Col } from 'react-bootstrap';
import { Loading } from './loading';
import { Pager } from './pager';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { DropdownButton } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
	displayPeriod,
	ItemsAndFilteredPaged,
	nameOfCurrentPeriod,
	nameOfPreviousPeriod,
	parse_period,
	subtract_period
} from "./dynamic-load";

/** 
 * Component with buttons for dynamic loading and label of displayed period, realod button, number of items/filtered and pager.  
 * @param {string} period - period to be loaded (a slice of time) N[wmqy], default N=1
 * @param {boolean} load_previous - whether we want to add previous period to loading
 * @param {function} loadData - function for api request
 * @param {int} filteredCount - entries at the page after filtering
 * @param {number} offset - start of pager
 * @param {function} setOffset - function changing start of pager
 * @param {int} totalCount - total number of entries in database
 * @param {int} rangeCount - total number of entries in range
 * @param {function} cleanFilters - function for cleaning all filters on the page
 * @return - Visual header with reload button, dynamic loading button, items/filtered and pager.
 */
export function DynamicLoadPaged({
	period, load_previous = false, loadData,
	filteredCount, offset, setOffset, totalCount, rangeCount, cleanFilters
	// lastNormal = false
}) { //if flexibleRange, tak první end_date bude null
	const { t } = useTranslation();
	const pageSize = 20;

	const its_last_in_month = date_isLastInMonth();

	const first_end_date = date_formatISO(date_getTomorrow());
	const load_period = parse_period(period, first_end_date);
	const first_start_date = (load_previous || its_last_in_month) ? subtract_period(load_period.current, load_period) : load_period.current;
	//override_load_previous
	//const [realCurrentStart, setRealCurrentStart] = useState(first_start_date);
	const [rangeStart, setRangeStart] = useState(first_start_date);
	const [rangeEnd, setRangeEnd] = useState(first_end_date);
	const [loadingState, setLoadingState] = useState(0); //states: 0 - loading, 1 - ok, 2 - error 
	const loadDataStornoRef = useRef(null);
	const dataEnd = rangeCount === totalCount;

	const reloadIt = () => {
		setLoadingState(0);
		//než zavolám nové loadData, tak se třeba ujistit, že neběží starý 
		if (loadDataStornoRef.current !== null) {
			loadDataStornoRef.current();
		} //musím to zavolat jako funkci, pokud je prázdná, nic se nestane, ale pokud to běží, tak se to stornuje
		loadDataStornoRef.current = loadData(rangeStart, rangeEnd, result_received, pageSize, offset, true);
	}

	function result_received(result) {
		loadDataStornoRef.current = null;
		if (result) {
			setLoadingState(1);
		} else {
			setLoadingState(2);
		}
	}

	function go_back() {
		setLoadingState(0);
		const new_start_date = subtract_period(rangeStart, load_period);
		setRangeStart(new_start_date);
	}

	useEffect(() => {
		setLoadingState(0);
		loadDataStornoRef.current = loadData(rangeStart, rangeEnd, result_received, pageSize, offset); 
		return function () {
			loadDataStornoRef.current?.();
		}
	}, [rangeEnd, rangeStart, loadData, offset]);

	const loading = loadingState === 0;
	
	const variant = loadingState === 2 ? "danger" : "primary";
	const loadedStuff = rangeCount + "/" + (totalCount ? totalCount : "-");


	return (
		<>
			<Row className="g-0">
				<Col xs="12" md="4">
					<Button size="sm" className="me-2 d-inline" onClick={reloadIt}><Image src="/img/reload.svg" height="19" /></Button>

					<ButtonGroup style={{ width: "200px" }}>
						<Button size="sm"
							variant={variant}
							disabled={loading || dataEnd}
							onClick={go_back}
							title={dataEnd ? t('all_loaded') : ""}
						>
							{loading ? <Loading message={t('loading')} margin="0" size="small" /> :
								<>
									{t('show_also')}{loadingState === 2 ? nameOfCurrentPeriod(period, rangeStart, t) : nameOfPreviousPeriod(period, rangeStart, t)}
								</>
							}
						</Button>

						<DropdownButton size="sm" as={ButtonGroup} title="" id="bg-nested-dropdown">
							<div className='px-3 py-2' style={{ maxWidth: "300px", width: "300px" }}>
								<small className='text-muted'>{t('loaded')} {loadedStuff} {t('records_from_db')}.</small>
							</div>
						</DropdownButton>
					</ButtonGroup>
					<span className='' style={{ minWidth: "300px", maxWidth: "300px" }}>
						&nbsp;&nbsp;
						{t('showing')}&nbsp;{displayPeriod(rangeStart, first_start_date, period, loadingState, t)} &nbsp;
					</span>
					{loadingState === 2 ? <><br /><small className='ms-5  mt-1'>⚠️ {t('loading_fail')}</small></> : ""}
				</Col>
				<Col xs="12" md="4" className='text-center'>
					<ItemsAndFilteredPaged totalCount={rangeCount} filteredCount={filteredCount} cleanFilters={cleanFilters} />
				</Col>
				<Col xs="12" md="4">
					<Pager offset={offset} pagesize={pageSize} total={filteredCount} callback={setOffset} disabled={loading} />
				</Col>
			</Row>
		</>
	);
}

