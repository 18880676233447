
/**
* Stock notifications that are being edited at the moment - superuser has right to cancel them
* @module orders/stock-notifications-open
* @author Lucie Zdeňková <lucie.zdenek@trustica.cz>
*/

import React, { useState, useEffect } from 'react';
import { his_fetch, his_fetch_success, HisFetchStatus } from '../comp/FetchLoader';
import { Table } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { date_time_format } from '../lib/date-utils';
import { Image } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { ErrorWrap } from '../comp/errorwrap';
import { useTranslation } from 'react-i18next';

//seen all - OK

/**
 * function that kills notification process for particular user
 * 
 * @param {*} ID_dokladu - id of order
 * @param {*} opened_by - user who made notification
 * @param {*} reloadIt - reload of order 
 * @param {*} userlogin - info about logged user
 */
export function endNotification(ID_dokladu, opened_by, reloadIt, userlogin) {
    his_fetch(
        userlogin,
        [
            {
                uri: "/api/stock-notifications/" + ID_dokladu + "/open/" + opened_by,
                json: true,
                ok: function (resource, result) {
                    console.log("closed: " + result.result);
                    reloadIt();
                },
                error: function (resource, reason) {
                    console.log('err: ' + reason);
                },
                args: {
                    method: 'DELETE',
                }
            }
        ]
    );
}

/**
 * List of CSR workers' unfinished notifications
 * 
 * @param {any} userlogin - info about logged user
 * @returns {component}
 */
export function StockNotificationsOpen({ userlogin }) {
    const { t } = useTranslation();

    const [opens, setOpens] = useState(null);
    const [loadedStatus, setLoadedStatus] = useState(0);

    const reloadIt = () => {
        setOpens(null);
        setLoadedStatus(0);
    }

    //console.log(opens);

    useEffect(() => {
        if (opens === null) {
            const running_fetch = his_fetch(
                userlogin,
                [
                    {
                        uri: "/api/stock-notifications/open/new",
                        json: true,
                        status: setLoadedStatus,
                        ok: function (resource, result) {
                            //console.log(result);
                            setOpens(result);
                            //console.log(opens);
                        },
                        error: function (resource, reason) {
                            console.log('err: ' + reason);
                            setOpens("error");
                        }
                    }
                ]
            )
            return () => {
                running_fetch();
            }
        }
    }, [userlogin, opens]);


    if (!his_fetch_success(loadedStatus)) {
        return <HisFetchStatus status={loadedStatus} loadingType="big" errorType="fetcherError" reloadButton={reloadIt} />;
    }

    const usable_opens = (opens === null || opens === "error") ? [] : opens;


    return (
        <ErrorWrap>
            <Button size="sm" className="me-2 mb-2 d-inline" onClick={reloadIt}><Image src="/img/reload.svg" height="19" /></Button>
            {usable_opens.length === 0 ? t('ord-no_sn_now') :
                <Table size="sm" striped>
                    <thead className="beGray">
                        <tr>
                            <th>{t('not-user')}</th>
                            <th>{t('ord-open_time')}</th>
                            <th>{t('doc_code')}</th>
                            <th>{t('ord-terminating_sn')}</th>

                        </tr>
                    </thead>
                    <tbody>
                        {usable_opens.map(function (o) {
                            return (
                                <tr key={o.openedAt}>
                                    <td className='align-middle'>{o.username}</td>
                                    <td className='align-middle'>{date_time_format(o.openedAt)}</td>
                                    <td className='align-middle'>
                                        <LinkContainer to={{ pathname: "/orders/purchase/" + o.orderCode }}>
                                            <a href="!#">{o.orderCode}</a>
                                        </LinkContainer>
                                    </td>
                                    <td><Button variant="dark" size="sm" onClick={() => endNotification(o.orderId, o.openedBy, reloadIt, userlogin)}>{t('ord-terminate_sn')}</Button></td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            }
        </ErrorWrap>
    );
}