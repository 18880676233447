/**
* Orders navigation component
* @module orders/orders
* @author Lucie Zdeňková <lucie.zdenek@trustica.cz>
*/

import React from 'react';
import Nav from 'react-bootstrap/Nav';
import { LinkContainer } from 'react-router-bootstrap';
import { Route, Routes } from "react-router-dom";
import { OrdersPurchase } from './orders-purchase';
import { OrderPurchase } from './order-purchase';
import { OrderSaleDetail } from './order-sale-detail';
import { OrdersSale } from './orders-sale';
import { StockNotificationsOpen } from './stock-notifications-open';
import { StockNotifyStatistics } from './stock-notifications-statistics';
import { StockNotifyTracking } from './stock-notify-tracking';
import { Messenger } from '../comp/messenger.js';
import { ErrorWrap } from '../comp/errorwrap';
import { Stockins } from './stockins';
import { useTranslation } from 'react-i18next';
import { ChooseCategory } from '../comp/choose-category';
import { OrdersPurchasePaged } from "./orders-purchase-paged";

//seen all - OK

/**
 * Component for rendering navigation of Orders.
 *
 * @param {string} userlogin - Userlogin.
 * @returns {component}  Returns Orders navigation
 **/

export function OrdersComponent({ userlogin }) {
	const { t } = useTranslation();
	const authorizedUser = userlogin.userinfo.hisSuperuser === true || userlogin.userinfo.roleCompliance === true || userlogin.userinfo.roleCsrHead === true;

	return (
		<ErrorWrap>
			<Nav variant="tabs" className="mb-3">
				{/*<Nav.Item>
					<LinkContainer to="/orders/purchase/old">
						<Nav.Link eventKey="/orders/purchase/old">{t('menu2-ord-pur') + ' old'}</Nav.Link>
					</LinkContainer>
				</Nav.Item>*/}
				<Nav.Item>
					<LinkContainer to="/orders/purchase">
						<Nav.Link eventKey="/orders/purchase">{t('menu2-ord-pur')}</Nav.Link>
					</LinkContainer>
				</Nav.Item>
				<Nav.Item>
					<LinkContainer to="/orders/sale">
						<Nav.Link eventKey="/orders/sale">{t('menu2-ord-sal')}</Nav.Link>
					</LinkContainer>
				</Nav.Item>
				<Nav.Item>
					{authorizedUser ?
						<LinkContainer to="/orders/open">
							<Nav.Link eventKey="/orders/open">{t('menu2-ord-sn_op')}</Nav.Link>
						</LinkContainer>
						: false}
				</Nav.Item>
				<Nav.Item>
					<LinkContainer to="/orders/tracking">
						<Nav.Link eventKey="/orders/tracking">{t('menu2-ord-track')}</Nav.Link>
					</LinkContainer>
				</Nav.Item>
				<Nav.Item>
					<LinkContainer to="/orders/statistics">
						<Nav.Link eventKey="/orders/statistics">{t('menu2-ord-stat')}</Nav.Link>
					</LinkContainer>
				</Nav.Item>
				<Nav.Item>
					<LinkContainer to="/orders/stockins">
						<Nav.Link eventKey="/orders/stockins">{t('menu2-ord-stckn')}</Nav.Link>
					</LinkContainer>
				</Nav.Item>
				<Nav.Item className="ms-auto">
					<Routes >
						<Route path="purchase/:code" element={<Messenger userlogin={userlogin} type={'nod'} />} />
					</Routes>
				</Nav.Item>
			</Nav>
			<Routes>
				<Route path="purchase/:code/*" element={<OrderPurchase userlogin={userlogin} />} />
				<Route path="purchase/old" element={<OrdersPurchase userlogin={userlogin} />} />
				<Route path="purchase" element={<OrdersPurchasePaged userlogin={userlogin} />} />
				<Route path="sale/:code" element={<OrderSaleDetail userlogin={userlogin} />} />
				<Route path="sale" element={<OrdersSale userlogin={userlogin} />} />
				<Route path="open" element={<StockNotificationsOpen userlogin={userlogin} />} />
				<Route path="tracking" element={<StockNotifyTracking userlogin={userlogin} />} />
				<Route path="statistics" element={<StockNotifyStatistics userlogin={userlogin} />} />
				<Route path="stockins" element={<Stockins userlogin={userlogin} />} />
				<Route path="" element={<ChooseCategory />} />
			</Routes>
		</ErrorWrap>
	);
}
